@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@font-face {
    font-family: "dboffice";
    src: url("./assets/fonts/dbhea02.ttf") format('truetype');
}

html {
    position: relative;
    height: 100%;
    //font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-family: "dboffice";
}

body {
    position: relative;
    height: 100%;
    background-image: url("assets/img/bg3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    -webkit-transition: background-image 2s ease-in-out;
    -moz-transition: background-image 2s ease-in-out;
    -o-transition: background-image 2s ease-in-out;
    -khtml-transition: background-image 2s ease-in-out;
    transition: background-image 2s ease-in-out;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    text-align: center;
}

input, select, textarea, button{
    font-family: inherit;
}

.holder {
    position:relative;
    width:90%;
    text-align:center;
    margin: auto;
    margin-top: 180px;
}

img {
    max-width: 100%;
    height: auto;
    width: auto\9;
}

.fakelink {
    color: blue;
    cursor: pointer;
}

.fakelink:hover {
    color:pink;
}

.loading {
    position: absolute;
    width: 80px;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
}

/*.maintable tbody:nth-child(odd) {background-color: #f2f2f2;}*/
.maintable tbody:nth-child(odd) {background-color: rgba(242,242,242,0.95);}
/*.maintable tbody:nth-child(even) {background-color: #ffffff;}*/
.maintable tbody:nth-child(even) {background-color: rgba(255,255,255,0.95);}

.maintable tr:hover {background-color: #9e9e9e;}


.maintable{
    border-collapse: collapse;
    width: 100%;
}

.maintable td, .maintable th, .maintable button {
    border: 1px solid #ddd;
    padding: 8px;
}

.maintable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
	color: white;
	background-color: rgba(38, 86, 241, 0.8);
	font-weight: normal;
}

.maintable button{
    width: 80%;
    height: 100%;
	min-width: 80px;
	background-color: rgba(0,0,0,0);
	color: black;
	
    user-select: none;
    cursor: pointer;
	-webkit-user-select: none; /* Chrome/Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none;
}

.maintable input {
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    padding: 3px;
}

.maintable button:hover {
    background-color: #95d788;
}

.reject:hover button{
    background-color: red;
}

.maintable td:not(.btnholder) {
    text-align: left;
	cursor: pointer;
}

.maintable .btnholder {
    text-align: center;
}

.btnholder button {
    width: 100%;
}

.maintable textarea {
    width: 100%;
}
